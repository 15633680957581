<template>
    <v-card v-if="project" v-show="issues > 0">
        <v-card-title>
            <v-icon>mdi-tag</v-icon>
            {{ version.name }}
        </v-card-title>
        <v-card-subtitle>
            <v-icon>mdi-calendar</v-icon>
            {{ formatDate(version.date) }}
        </v-card-subtitle>
        <v-card-text>
            <v-progress-linear
                rounded
                v-model="advanceVersion"
                color="light-blue darken-4"
                height="25"
                :dark="advanceVersion > 45"
                :striped="advanceVersion < 100"
            >
            </v-progress-linear>
            <small
                >{{ issues }} {{ $t('issues.actions.total_issues') }} ({{ closeIssues }}
                {{ $t('issues.actions.close_issues') }} - {{ issues - closeIssues }}
                {{ $t('issues.actions.open_issues') }})</small
            >
        </v-card-text>
        <v-card-text v-show="issues > 0">
            <issues-groups
                v-if="displayGroup"
                :view.sync="view"
                :filters.sync="filters"
                v-on:update-group="updateGroup"
            />
            <issues-view
                :view.sync="view"
                :filters.sync="filters"
                :group.sync="group"
                :allow-select="false"
                :show-footer="false"
                v-on:total-issues="updateTotalIssues"
                v-on:getted-issues="calculateProgress"
                :show-header="false"
            />
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import issuesGroups from '../../../issues/issue/groups';
import issuesView from '../../../issues/issue/view';
import { formatDateMixin } from '@/mixins/dates';

export default {
    name: 'roadmap-version',
    data: () => ({
        displayGroup: true,
        group: undefined,
        issues: 0,
        advanceVersion: 0,
        closeIssues: 0,
    }),
    mixins: [formatDateMixin],
    components: {
        issuesGroups,
        issuesView,
    },
    props: {
        version: {
            default: undefined,
        },
    },
    computed: {
        ...mapGetters(['project', 'status']),
        view() {
            return this.project.views.find((v) => v.name === 'roadmap');
        },
        filters() {
            if (this.version) {
                return [
                    {
                        field: 'versionTarget',
                        operator: '$in',
                        value: this.version.name,
                    },
                ];
            }
            return [];
        },
    },
    methods: {
        updateGroup(group) {
            this.group = group;
        },
        updateTotalIssues(count) {
            this.issues = count;
        },
        calculateProgress(issues) {
            const closeStatus = this.status.filter((s) => !s.open).map((s) => s.name);
            this.closeIssues = issues.filter((i) => closeStatus.includes(i.status)).length;
            const total = issues.length;
            this.advanceVersion = (this.closeIssues * 100) / total;
        },
    },
};
</script>

<style></style>
