<template>
    <v-row>
        <v-col cols="12" md="10" offset-md="1" v-for="(version, index) in versions" :key="index">
            <roadmap-version :version="version" />
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import roadmapVersion from './roadmap/version';
import OrderBy from 'lodash/orderBy';

export default {
    name: 'project-roadmap',
    data: () => ({}),
    components: {
        roadmapVersion,
    },
    computed: {
        ...mapGetters(['project']),
        versions() {
            if (this.project) {
                const versions = this.project.versions.filter((v) => v.development);
                return OrderBy(versions, ['date'], ['asc']);
            }
            return [];
        },
    },
};
</script>

<style></style>
